<template>
  <v-tooltip
    v-bind="$attrs"
    v-on="$listeners"
    :bottom="computedTooTipBottom"
    :disabled="!hasSlot('toolTip')"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" @click.stop="$emit('click')">
        <slot></slot>
      </v-icon>
    </template>
    <span>
      <slot name="toolTip"></slot>
    </span>
  </v-tooltip>
</template>

<script>
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "BaseTooltipIcon",
  inheritAttrs: false,
  mixins: [slottableMixin],
  props: {
    toolTipBottom: {
      type: Boolean,
      default: undefined
    }
  },
  computed: {
    computedTooTipBottom() {
      return this.toolTipBottom === undefined ? true : this.toolTipBottom;
    }
  }
};
</script>
